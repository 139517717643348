<template>
    <v-btn :disabled="props.disabled" :loading="props.loading" outlined color="transparent" class="secondary-button-border" :style="{'border': `${props.color ? `var(--v-${props.color}-base)  2px solid`: ''}`}" elevation="0" :block="props.block" :to="{ name: props.to }" @click="props.click" >
        <div :class="`secondary-button button ${props.textColor ? `${props.textColor}--text text--darken-${props.textColorLevel == undefined ? '1':props.textColorLevel}` : 'grey--text text--darken-3'}  ${props.icon == '' ? 'center-text': ''}`"  >
            {{props.text}}<template v-if="props.icon != ''">
                &nbsp;&nbsp;<v-icon :class="`${props.iconClass}`">{{props.icon}}</v-icon>
            </template>
        </div>
       
    </v-btn>
</template>
<script>
    export default {
        name: 'secondaryButton',
        props: ['props']
    }
</script>

<style lang="scss">
    .secondary-button{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        // min-width: 110px;

        &-border{
            border: var(--v-secondary-base)  1.5px solid;
        }
    }


    .center-text{
        justify-content: center;;
    }

   
</style>