<template>
    <v-snackbar v-model="props.visible" :bottom="props.bottom" :color="props.color" :left="props.left" :multi-line="props.multiline" :right="props.right" :timeout="props.timeout" :top="props.top" :vertical="props.vertical" transition="scale-transition">
        <v-row justify="space-between" align="center" class="pl-3">
            <div v-html="props.text"></div>
            <v-btn dark text icon @click="props.visible = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-row>

    </v-snackbar>
   
</template>
<script>
    export default {
        name: 'snackbar',
        props: ['props']
    }
</script>