<template>
	<v-navigation-drawer v-model="$store.state.menu" app color="primary" src="@/assets//background-sidenav.png">
		<v-list-item>
			<v-list-item-content>
				<v-img src="./../assets/logo.png" contain max-width="100%"></v-img>
			</v-list-item-content>
		</v-list-item>

		<v-divider></v-divider>

		<v-list dense tile flat dark>
			<div v-for="(modulo, i) in menu" :key="i">
				<v-list-group :data-cy="modulo.cy" v-if="modulo.submenu.length > 0" active-class="primary--text">
					<template v-slot:activator>
						<v-list-item-title>{{ modulo.title }}</v-list-item-title>
					</template>
					
					<v-list-item v-for="(submodulo, j) in modulo.submenu" :key="j" :to="submodulo.link" :inactive="submodulo.link === null" active-class="white--text border-orange" :data-cy="submodulo.cy">
						<!-- <v-list-item-icon><v-icon>{{ submodulo.icon }}</v-icon></v-list-item-icon> -->
						<v-list-item-title>{{ submodulo.title }}</v-list-item-title>						
					</v-list-item>
				</v-list-group>

				<v-list-item :data-cy="modulo.cy" :to="modulo.link" v-else :inactive="modulo.link === null" active-class="white--text border-orange">
					<!-- <v-list-item-icon><v-icon>{{ modulo.icon }}</v-icon></v-list-item-icon> -->
					<v-list-item-title>{{ modulo.title }}</v-list-item-title>
				</v-list-item>
			</div>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
	export default {
		name: 'Sidemenu',
		data () {
			return {
				menu: [
					{
						title: 'DASHBOARD',
						name: 'Dashboard',
						icon: 'mdi-view-dashboard',
						submenu: [],
						link: '/dashboard',
                        cy:"dashboard-side-items",
					},
					{
						title: 'OBRAS',
						name: 'Works',
						icon: 'mdi-account-hard-hat',
						submenu: [],
						link: '/works',
                        cy:"works-side-items",
					},
					{
						title: 'COMPRAS',
						name: 'Purchases',
						icon: 'mdi-cart',
                        cy:"purchases-side-items",
						submenu: [
							{ title: 'PROVEEDORES', icon: 'mdi-star-face', name: 'Suppliers', link: '/suppliers', cy:"suppliers-link"},
							{ title: 'MATERIAS PRIMAS', icon: 'mdi-star-face', name: 'Commodities', link: '/commodities', cy: "commodities-link"},
							{ title: 'REQUISICIONES', icon: 'mdi-star-face', name: 'Requisition', link: '/requisitions', cy: "requisitions-link"},
							{ title: 'ÓRDENES DE COMPRA', icon: 'mdi-star-face', name: 'PurchaseOrders', link: '/purchase-orders', cy: "purchaseOrders-link"},
						]
					},
					{
						title: 'VENTAS',
						name: 'Ventas',
						icon: 'mdi-briefcase-account',
                        cy:"sales-side-items",
						submenu: [
							{ title: 'CLIENTES', icon: 'mdi-star-face', name: 'Clients', link: '/clients', cy:"clients-link"},
							{ title: 'COTIZACIONES', icon: 'mdi-star-face', name: 'Quotations', link: '/quotations',cy:"quotations-link"},
							{ title: 'ORDENES DE TRABAJO', icon: 'mdi-star-face', name: 'SaleOrders', link: '/sale-orders', cy:"saleOrders-link"},
							{ title: 'PRODUCTOS', icon: 'mdi-star-face', name: 'Products', link: '/products', cy:"products-link"},
						]
					},
					{
						title: 'CONTABILIDAD',
						name: 'Contabilidad',
						icon: 'mdi-kabaddi',
                        cy:"accounting-side-items",
						submenu: [
							{ title: 'BANCOS', icon: 'mdi-star-face', name: 'BankAccounts', link: '/banks', cy:"bankAccounts-link"},
							{ title: 'CUENTAS X COBRAR', icon: 'mdi-star-face', name: 'ReceivableAccounts', link: '/receivable-accounts', cy: "receibableAccounts-link"},
							{ title: 'CUENTAS X PAGAR', icon: 'mdi-star-face', name: 'DebtsToPay', link: '/debts-to-pay', cy:"debtsToPay-link"},
						]
					},
					{
						title: 'ACCESOS',
						name: 'Configuración',
						icon: 'mdi-kabaddi',
                        cy:"access-side-items",
						submenu: [
							{ title: 'USUARIOS', icon: 'mdi-star-face', name: 'Users', link: '/users', cy:"users-link"},
							{ title: 'EMPLEADOS', icon: 'mdi-star-face', name: 'Employees', link: '/employees', cy:"employees-link"},
						]
					},
					{
						title: 'CATÁLOGOS',
						name: 'Catalogs',
						icon: 'mdi-book-open-blank-variant',
                        cy:"catalog-side-items",
						submenu: [
							{ title: 'BANCOS', icon: 'mdi-bank', name: 'Banks', link: '/catalogs/banks', cy:"banks-link"},
							{ title: 'UNIDADES', icon: 'mdi-set-square', name: 'Units', link: '/catalogs/units', cy:"units-link"},
						]
					},
					
					{
						title: 'REPORTES',
						name: 'Reports',
						icon: 'mdi-account-hard-hat',
                        cy:"reports-side-items",
						submenu: [
							{ title: 'COSTOS POR OT', icon: 'mdi-bank', name: 'PurchaseOrdersByWork', link: '/reports/purchase-orders-by-work', cy:"purchaseOrdersByWork-link"},
							{ title: 'MATERIAL POR OT', icon: 'mdi-bank', name: 'PurchaseOrdersVariantsByWork', link: '/reports/purchase-orders-variants-by-work', cy:"PurchaseOrdersVariantsByWork-link"},
							{ title: 'Material', icon: 'mdi-bank', name: 'PurchaseOrdersByVariant', link: '/reports/purchase-orders-by-variant', cy:"PurchaseOrdersByVariant-link"},
							// { title: 'CUENTAS POR PAGAR', icon: 'mdi-bank', name: 'ReporteCuentas', link: '/reportes/cuentas'},
						]
					},	
				],
				right: null,
			}
		},
		methods: {
			
		}
	};
</script>
<style lang="css">
	.v-list-group__items .v-list-item {
		padding-left: 30px;
	}

	.border-orange{
		border: 1px solid #cb6120;
		border-left-width: 6px;
	}

	.v-list-group__items .v-list-item.border-orange {
		padding-left: 24px !important;
	}

	.v-list-item.border-orange{
		padding-left: 10px !important;
	}

	.v-list--dense .v-list-item .v-list-item__icon{
		margin-top: 7px;
		margin-bottom: 7px;
	}
</style>