import AuthService from "./AuthService";
import CatalogService from "./CatalogServices";
import EmployeeService from "./EmployeeService";
import UserService from "./UserService";
import SupplierService from "./SupplierService";
import AddressAPIService from "./AddressAPIService";
import CommodityService from "./CommodityService";
import ClientService from "./ClientService";
import ProductService from "./ProductService";
import QuotationService from "./QuotationService";
import BankAccountService from "./BankAccountService";
import SaleOrderService from "./SaleOrderService";
import RequisitionService from "./RequisitionService";
import PurchaseOrderService from "./PurchaseOrderService";
import ReportService from "./ReportService";
import WorkService from "./WorkService";

export const $api = {
   
    auth: new AuthService(),
    catalog: new CatalogService(),
    employee: new EmployeeService(),
    user: new UserService(),
    supplier: new SupplierService(),
    addressApi: new AddressAPIService(),
    commodity: new CommodityService(),
    client: new ClientService(),
    product: new ProductService(),
    quotation: new QuotationService(),
    bankAccount: new BankAccountService(),
    saleOrder: new SaleOrderService(),
    requisition: new RequisitionService(),
    purchaseOrder: new PurchaseOrderService(),
    report: new ReportService(),
    work: new WorkService(),
    
    
};