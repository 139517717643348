import BaseApi from "./BaseApi";

export default class CatalogService extends BaseApi {

    constructor(){
        super();
    }

    async list(catalogType)
    {
        return this.http.get(`${this.baseUrl}/catalogs/${catalogType}`)
        .catch(this.handleErrorResponse);
    }

    async get(id)
    {
        return this.http.get(`${this.baseUrl}/catalogs/${id}/edit`)
        .catch(this.handleErrorResponse);
    }

    async store(data)
    {
        return this.http.post(`${this.baseUrl}/catalogs`, data)
        .catch(this.handleErrorResponse);
    }

    async update(id, data)
    {
        return this.http.put(`${this.baseUrl}/catalogs/${id}`, data)
        .catch(this.handleErrorResponse);
    }

    async delete(id)
    {
        return this.http.delete(`${this.baseUrl}/catalogs/${id}`)
        .catch(this.handleErrorResponse);
    }
    
    
    async getUnits()
    {
        return this.http.get(`${this.baseUrl}/catalogs/gets/getUnits`)
        .catch(this.handleErrorResponse);
    }

    async getBanks()
    {
        return this.http.get(`${this.baseUrl}/catalogs/gets/getBanks`)
        .catch(this.handleErrorResponse);
    }

}