<template>
   
    <v-card v-if="show" class="main-card d-flex" >
       
		<v-row justify="center" align="center">
			<!-- <v-col align-self="center"> -->
				<v-progress-circular
					size="50"
					indeterminate
					color="secondary"
				></v-progress-circular>
			<!-- </v-col> -->
		</v-row>
	</v-card>
   
</template>
<script>
export default {
    props: ['show'],
   
    computed: {
      
    }
}
</script>