import BaseApi from "./BaseApi";

export default class ProductService extends BaseApi {

    constructor(){
        super();
    }

    async list()
    {
        return this.http.get(`${this.baseUrl}/products`)
        .catch(this.handleErrorResponse);
    }

    async get(id)
    {
        return this.http.get(`${this.baseUrl}/products/${id}`)
        .catch(this.handleErrorResponse);
    }


    async getSelect()
    {
        return this.http.get(`${this.baseUrl}/products/gets/getProducts`)
        .catch(this.handleErrorResponse);
    }

   

    async store(data)
    {
        return this.http.post(`${this.baseUrl}/products`, data)
        .catch(this.handleErrorResponse);
    }

    async update(id, data)
    {
        return this.http.put(`${this.baseUrl}/products/${id}`, data)
        .catch(this.handleErrorResponse);
    }

    async delete(id)
    {
        return this.http.delete(`${this.baseUrl}/products/${id}`)
        .catch(this.handleErrorResponse);
    }


}