<template>
    <v-radio
        :label="label"
        :value="value"
        color="primary"
        :title="title"
    ></v-radio>

</template>
<script>
export default {
    props: ['valueInput', 'rule', 'label', 'title'],
    model: {
        prop: 'value',
        event: 'valueChange'
    },
    computed: {
        value: {
            get: function() {
                return this.valueInput
            },
            set: function(value) {
                this.$emit('valueChange', value)
            }
        }
    }
}
</script>

<style lang="scss">
    .v-input--selection-controls .v-radio > .v-label{
        padding-top: 0.3125rem !important;
        font-size: 0.9375rem;
    }
</style>