import Home from '../views/Home.vue';
//import testMiddle from "../middleware/testMiddle";
import AuthMiddleware from '../middleware/AuthMiddleware';
// import VueGateMiddleware from '../middleware/VueGateMiddleware';

const initialRoutes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            auth: false,
            title: 'Grupo Merca',
            middlewares: {AuthMiddleware}
        }
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: {
            auth: false,
            title: 'Grupo Merca',
            middlewares: {AuthMiddleware}
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "about" */ '../layouts/Login.vue'),
        meta: {
            auth: false,
            title: 'Grupo Merca',
            middlewares: {AuthMiddleware}
        }
  },
];

const dashboardRoutes = [
    /*
        ! Work
    */
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('../views/dashboard/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Dashboard',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
];

const workRoutes = [
    /*
        ! Work
    */
    {
        path: '/works',
        name: 'Works',
        component: () => import('../views/works/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Obras',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/works/:id/edit',
        name: 'WorksEdit',
        component: () => import('../views/works/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Editar Obra',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/works/:id/details',
        name: 'WorksDetails',
        component: () => import('../views/works/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Detalles Obra',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
];

const purchasesRoutes = [

    /*
        ! Suppliers
    */
    {
        path: '/suppliers',
        name: 'Suppliers',
        component: () => import('../views/purchases/suppliers/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Proveedores',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/suppliers/new',
        name: 'SuppliersCreate',
        component: () => import('../views/purchases/suppliers/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Nuevo Proveedor',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/suppliers/:id/edit',
        name: 'SuppliersEdit',
        component: () => import('../views/purchases/suppliers/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Editar Proveedor',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/suppliers/:id/details',
        name: 'SuppliersDetails',
        component: () => import('../views/purchases/suppliers/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Detalles Proveedor',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },


    /*
        ! Commodities
    */
    {
        path: '/commodities',
        name: 'Commodities',
        component: () => import('../views/purchases/commodities/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Materias primas',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/commodities/new',
        name: 'CommoditiesCreate',
        component: () => import('../views/purchases/commodities/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Nueva Materia prima',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/commodities/:id/edit',
        name: 'CommoditiesEdit',
        component: () => import('../views/purchases/commodities/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Editar Materia prima',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/commodities/:id/details',
        name: 'CommoditiesDetails',
        component: () => import('../views/purchases/commodities/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Detalles Materia prima',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },

    /*
        ! Requisitions
    */
    {
        path: '/requisitions',
        name: 'Requisitions',
        component: () => import('../views/purchases/requisitions/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Requisiciones',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/requisitions/new',
        name: 'RequisitionsCreate',
        component: () => import('../views/purchases/requisitions/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Nueva Requisición',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/requisitions/new/:id',
        name: 'RequisitionsCopy',
        component: () => import('../views/purchases/requisitions/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Nueva Requisición',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/requisitions/:id/edit',
        name: 'RequisitionsEdit',
        component: () => import('../views/purchases/requisitions/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Editar Requisición',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/requisitions/:id/details',
        name: 'RequisitionsDetails',
        component: () => import('../views/purchases/requisitions/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Detalles Requisición',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },

    /*
        ! Purchase orders
    */
    {
        path: '/purchase-orders',
        name: 'PurchaseOrders',
        component: () => import('../views/purchases/purchaseOrders/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Órdenes de compra',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/purchase-orders/new/:requisitionId',
        name: 'PurchaseOrdersCreate',
        component: () => import('../views/purchases/purchaseOrders/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Órdenes de compra',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/purchase-orders/:id/edit',
        name: 'PurchaseOrdersEdit',
        component: () => import('../views/purchases/purchaseOrders/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Editar Orden de compra',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/purchase-orders/:id/details',
        name: 'PurchaseOrdersDetails',
        component: () => import('../views/purchases/purchaseOrders/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Detalles Orden de compra',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    //Payments
    {
        path: '/purchase-orders/:purchaseOrderId/payments/new',
        name: 'PurchaseOrdersPaymentCreate',
        component: () => import('../views/purchases/purchaseOrders/payments/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Nuevo pago',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/purchase-orders/:id/payments/:paymentId',
        name: 'PurchaseOrdersPaymentEdit',
        component: () => import('../views/purchases/purchaseOrders/payments/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Compras - Editar pago',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
];


const salesRoutes = [
    /*
        ! Clients
    */
    {
        path: '/clients',
        name: 'Clients',
        component: () => import('../views/sales/clients/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Clientes',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/clients/new',
        name: 'ClientsCreate',
        component: () => import('../views/sales/clients/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Nuevo Cliente',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/clients/:id/edit',
        name: 'ClientsEdit',
        component: () => import('../views/sales/clients/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Editar Cliente',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/clients/:id/details',
        name: 'ClientsDetails',
        component: () => import('../views/sales/clients/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Detalles Cliente',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },

    /*
        ! Quotations
    */
    {
        path: '/quotations',
        name: 'Quotations',
        component: () => import('../views/sales/quotations/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Cotizaciones',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/quotations/new/:id?',
        name: 'QuotationsCreate',
        component: () => import('../views/sales/quotations/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Nueva Cotización',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/quotations/:id/edit',
        name: 'QuotationsEdit',
        component: () => import('../views/sales/quotations/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Detalles Cotización',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/quotations/:id/details',
        name: 'QuotationsDetails',
        component: () => import('../views/sales/quotations/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Detalles Cotización',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },


    /*
        ! Sale orders
    */
    {
        path: '/sale-orders',
        name: 'SaleOrders',
        component: () => import('../views/sales/saleOrders/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Ordenes de trabajo',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/sale-orders/:quotationId/new',
        name: 'SaleOrdersCreate',
        component: () => import('../views/sales/saleOrders/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Nueva Orden de trabajo',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/sale-orders/:id/edit',
        name: 'SaleOrdersEdit',
        component: () => import('../views/sales/saleOrders/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Editar Orden de trabajo',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/sale-orders/:saleOrderId/details',
        name: 'SaleOrdersDetails',
        component: () => import('../views/sales/saleOrders/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Detalles Orden de trabajo',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    //Tracings
    {
        path: '/sale-orders/:saleOrderId/tracings/new',
        name: 'SaleOrdersCreateTracing',
        component: () => import('../views/sales/saleOrders/tracings/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Nuevo seguimiento',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    //payments
    {
        path: '/sale-orders/:saleOrderId/payments/new',
        name: 'SaleOrdersCreatePayment',
        component: () => import('../views/sales/saleOrders/payments/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Nuevo Pago',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/sale-orders/:saleOrderId/payments/:paymentId/edit',
        name: 'SaleOrdersEditPayment',
        component: () => import('../views/sales/saleOrders/payments/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Editar Pago',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },



    /*
        ! Products
    */
    {
        path: '/products',
        name: 'Products',
        component: () => import('../views/sales/products/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Productos',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/products/new',
        name: 'ProductsCreate',
        component: () => import('../views/sales/products/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Nuevo Producto',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/products/:id/edit',
        name: 'ProductsEdit',
        component: () => import('../views/sales/products/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Ventas - Editar Producto',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
];

const accountingRoutes = [
    /*
        ! Banks
    */
    {
        path: '/banks',
        name: 'BanksAccounts',
        component: () => import('../views/accounting/banks/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Contabilidad - Bancos',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/banks/new',
        name: 'BanksAccountsCreate',
        component: () => import('../views/accounting/banks/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Contabilidad - Nuevo Banco',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/banks/:id/edit',
        name: 'BanksAccountsEdit',
        component: () => import('../views/accounting/banks/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Contabilidad - Editar Banco',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/banks/:id/details',
        name: 'BanksAccountsDetails',
        component: () => import('../views/accounting/banks/Details.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Contabilidad - Detalles Banco',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/banks/:id/transactions/new',
        name: 'BanksAccountsCreateTransaction',
        component: () => import('../views/accounting/banks/transactions/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Contabilidad - Nueva Transacción',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/banks/:id/transactions/:transaction_id',
        name: 'BanksAccountsEditTransaction',
        component: () => import('../views/accounting/banks/transactions/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Contabilidad - Editar Transacción',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },


    /*
        ! Receivable accounts
    */
    {
        path: '/receivable-accounts',
        name: 'ReceivableAccounts',
        component: () => import('../views/accounting/receivableAccounts/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Contabilidad - Cuentas x cobrar',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/receivable-accounts/:id/details',
        name: 'ReceivableAccountsDetails',
        component: () => import('../views/accounting/receivableAccounts/ByClient.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Contabilidad - Detalles de Cuentas x cobrar',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },

    /*
        ! Debts to pay
    */
    {
        path: '/debts-to-pay',
        name: 'DebtsToPay',
        component: () => import('../views/accounting/debtsToPay/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Contabilidad - Cuentas x Pagar',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/debts-to-pay/:id/details',
        name: 'DebtsToPayDetails',
        component: () => import('../views/accounting/debtsToPay/BySupplier.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Contabilidad - Detalles Cuentas x Pagar',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
];

const catalogRoutes = [
    

    /*
        ! Banks
    */
    {
        path: '/catalogs/banks',
        name: 'Banks',
        component: () => import('../views/catalogs/banks/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Bancos',
            middlewares: {AuthMiddleware},
            permissions: 'banks.show'
        }
    },
    {
        path: '/catalogs/banks/new',
        name: 'BanksCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/banks/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Nuevo Banco',
            middlewares: {AuthMiddleware},
            permissions: 'banks.store'
        }
    },
    {
        path: '/catalogs/banks/:id/edit',
        name: 'BanksEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/banks/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Editar Banco',
            middlewares: {AuthMiddleware},
            permissions: 'banks.edit'
        }
    },

   
    /*
        ! Units
    */
    {
        path: '/catalogs/units',
        name: 'Units',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/units/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Unidades',
            middlewares: {AuthMiddleware},
            permissions: 'units.show'
        }
    },
    {
        path: '/catalogs/units/new',
        name: 'UnitsCreate',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/units/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Nueva Unidad',
            middlewares: {AuthMiddleware},
            permissions: 'units.store'
        }
    },
    {
        path: '/catalogs/units/:id/edit',
        name: 'UnitsEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/catalogs/units/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Catálogos - Editar Unidad',
            middlewares: {AuthMiddleware},
            permissions: 'units.edit'
        }
    },
];

const accessRoutes = [
    /*
        ! Employees
    */
    {
        path: '/employees',
        name: 'Employees',
        component: () => import('../views/access/employees/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Accesos - Empleados',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/employees/new',
        name: 'EmployeesCreate',
        component: () => import('../views/access/employees/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Accesos - Nuevo Empleado',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/employees/:id/edit',
        name: 'EmployeesEdit',
        component: () => import('../views/access/employees/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Accesos - Editar Empleado',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    

    /*
        ! Users
    */
    {
        path: '/users',
        name: 'Users',
        component: () => import('../views/access/users/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Accesos - Usuarios',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/users/new',
        name: 'UsersCreate',
        component: () => import('../views/access/users/Create.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Accesos - Nuevo Usuario',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
    {
        path: '/users/:id/edit',
        name: 'UsersEdit',
        component: () => import('../views/access/users/Edit.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Accesos - Editar Usuario',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },

   
];

const reportsRoutes = [
    /*
        ! Purchase orders by work
    */
    {
        path: '/reports/purchase-orders-by-work',
        name: 'PurchaseOrdersByWork',
        component: () => import('../views/reports/purchaseOrderByWork/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Reportes - Costos por OT',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },

    /*
        ! Varians Purchase orders by work
    */
    {
        path: '/reports/purchase-orders-variants-by-work',
        name: 'PurchaseOrdersVariantsByWork',
        component: () => import('../views/reports/variantPoByWork/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Reportes - Material por OT',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },

    /*
        ! Purchase orders by variant
    */
    {
        path: '/reports/purchase-orders-by-variant',
        name: 'PurchaseOrdersByVariant',
        component: () => import('../views/reports/purchaseOrderByVariant/Index.vue'),
        meta: {
            parent:'',
            auth: true,
            title: 'Reportes - Reporte de material',
            middlewares: {AuthMiddleware},
            // permissions: 'banks.show'
        }
    },
];


const routes = [
    ...initialRoutes,
    ...catalogRoutes,
    ...accessRoutes,
    ...purchasesRoutes,
    ...salesRoutes,
    ...accountingRoutes,
    ...reportsRoutes,
    ...workRoutes,
    ...dashboardRoutes,

];
export default routes;