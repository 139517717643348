<template>
  <div>
    <v-data-table :hide-default-footer="props.hideFooter" :sort-by.sync="props.sortBy"
        :sort-desc.sync="props.sortDesc" :loading="props.loading" :class="`elevation-${props.elevation??'3'} ${props.loading ? '': 'table-b-border-header'}`" no-results-text="No hay resultados" no-data-text="No hay datos" :headers="props.headers" :items="props.items" :search="props.search" :footer-props="{
          itemsPerPageOptions:[13, 25, 50, 100]
        }">
        <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top v-for="(action, i) of item.actions" :key="i">
                <template v-slot:activator="{ on }">
                    <v-btn :data-cy="action.dataCy" :loading="action.loading" x-small v-if="action.type === 'method'" :class="`mx-0 `" icon @click="methodHandler(action.action, item.parameters)" :disabled="action.disabled">
                        <v-icon  :class="`${action.class}`" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                    </v-btn>
                    <v-btn :data-cy="action.dataCy"  :loading="action.loading" x-small v-else-if="action.type === 'redirect'" icon text :to="action.action" :disabled="action.disabled">
                        <v-icon :class="`${action.class} `" :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                    </v-btn>
                    <v-btn :data-cy="action.dataCy" :loading="action.loading" x-small v-else-if="action.type === 'external'" icon text target="_blank" :href="action.action" :disabled="action.disabled">
                        <v-icon  :class="`${action.class} `"  :color="action.icon.color" v-on="on">{{ action.icon.icon }}</v-icon>
                    </v-btn>
                </template>
                <span>{{ action.name }}</span>
            </v-tooltip>
        </template>
        <template
            v-for="slot in Object.keys($scopedSlots)"
            :slot="slot"
            slot-scope="scope"
            ><slot :name="slot" v-bind="scope"
        /></template>
    </v-data-table>
  </div>
</template>

<script>
    export default {
    
        name: 'datatable',
        props: ['props'],
        methods: {
            methodHandler(methodName, parameters){
                // console.log(methodName, para)
                this.$emit('methodHandler', {
                    methodName: methodName, 
                    parameters: parameters
                });
            },
            
        }
    }
</script>

<style>

</style>