<template>
	<v-card  height="100vh" flat class="d-flex flex-column justify-space-between align-center" >
        <div class="logo-loading">
		
					<!-- <v-img  width="250"  src="@/assets/Logotipo.png"></v-img> -->
			
		</div>
		<v-row>
			<v-col align-self="center">
				<v-progress-circular
					size="200"
					indeterminate
					color="secondary"
				></v-progress-circular>
			</v-col>
		</v-row>
	</v-card>
</template>

<script lang="ts">

	export default {
		name: 'loading',
		
	}
</script>

<style lang="scss">
	.logo-loading{
		position: absolute;
        margin: 50vh auto 0;
        transform: translateY(-50%);
    }
</style>