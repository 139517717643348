
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class AddressAPIService extends BaseApi {

    apiUrl = process.env.VUE_APP_API_LOCATIONS_URL;
    constructor(){
        super();
        
    }

    async getCountries()
    {
        return this.http.get(`${this.apiUrl}/getCountries`)
        .catch(this.handleErrorResponse);
    }

    async getStates(country)
    {
        return this.http.get(`${this.apiUrl}/getStates/${country}`)
        .catch(this.handleErrorResponse);
    }

    async getCities(country, state)
    {
        return this.http.get(`${this.apiUrl}/getCities/${country}/${state}`)
        .catch(this.handleErrorResponse);
    }


    async getCity(code)
    {
        return this.http.get(`${this.apiUrl}/getCityDetails/${code}`)
        .catch(this.handleErrorResponse);
    }





}