import BaseApi from "./BaseApi";

export default class CommodityService extends BaseApi {

    constructor(){
        super();
    }

    async list()
    {
        return this.http.get(`${this.baseUrl}/commodities`)
        .catch(this.handleErrorResponse);
    }

    async get(id)
    {
        return this.http.get(`${this.baseUrl}/commodities/${id}`)
        .catch(this.handleErrorResponse);
    }

   

    async store(data)
    {
        return this.http.post(`${this.baseUrl}/commodities`, data)
        .catch(this.handleErrorResponse);
    }

    async update(id, data)
    {
        return this.http.put(`${this.baseUrl}/commodities/${id}`, data)
        .catch(this.handleErrorResponse);
    }

    async delete(id)
    {
        return this.http.delete(`${this.baseUrl}/commodities/${id}`)
        .catch(this.handleErrorResponse);
    }


    async bySupplier(supplier_id)
    {
        return this.http.get(`${this.baseUrl}/commodities/bySupplier/${supplier_id}`)
        .catch(this.handleErrorResponse);
    }

    async getSelectAll()
    {
        return this.http.get(`${this.baseUrl}/commodities/gets/variants`)
        .catch(this.handleErrorResponse);
    }



}