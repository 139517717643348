<template>

    <v-btn :disabled="props.disabled" :to="{ name: props.to }" :loading="props.loading" @click="props.click" :color="props.color" elevation="0" small :height="props.height">
        <v-icon :class="`${props.iconClass}`">{{props.icon}}</v-icon>
    </v-btn>
</template>
<script>
    export default {
        name: 'iconButton',
        props: ['props']
    }
</script>

<style lang="scss">
  
</style>