import BaseApi from "./BaseApi";

export default class UserService extends BaseApi {

    constructor(){
        super();
    }

    async list()
    {
        return this.http.get(`${this.baseUrl}/users`)
        .catch(this.handleErrorResponse);
    }

    async get(id)
    {
        return this.http.get(`${this.baseUrl}/users/${id}`)
        .catch(this.handleErrorResponse);
    }

   

    async store(data)
    {
        return this.http.post(`${this.baseUrl}/users`, data)
        .catch(this.handleErrorResponse);
    }

    async update(id, data)
    {
        return this.http.put(`${this.baseUrl}/users/${id}`, data)
        .catch(this.handleErrorResponse);
    }

    async delete(id)
    {
        return this.http.delete(`${this.baseUrl}/users/${id}`)
        .catch(this.handleErrorResponse);
    }


    async changePassword(id, data){
        return this.http.put(`${this.baseUrl}/users/${id}/update-password`, data)
        .catch(this.handleErrorResponse);
    }
}