<template>
  <v-app>
    <component :is="this.$store.state.layout"></component>
    <snackbar :props="$store.state.snackbarProps"></snackbar>

    <!-- <loading-overlay></loading-overlay> -->
  </v-app>
</template>


<script>
import "@/scss/_stylesheet.scss";
import snackbar from './components/Snackbar.vue';
import Utils from "./helpers/Utils";

export default {
  components: { snackbar },
  name: 'App',

  data: () => ({
    //
  }),
  mounted(){
		this.setTheme(this.$vuetify.theme.themes.light.primary, this.$vuetify.theme.themes.light.secondary);
	},
	methods: {
		setTheme(primaryColor = "#fff", secondaryColor = "#000")
		{	
			//console.log(primaryColor, secondaryColor)
			this.$vuetify.theme.themes.light.primary = primaryColor;
			this.$vuetify.theme.themes.light.secondary = secondaryColor;

			const rgb = Utils.hexToRgb(this.$vuetify.theme.themes.light.primary);
			document.querySelector('meta[name="theme-color"]').setAttribute('content',  this.$vuetify.theme.themes.light.primary);
			document.querySelector(":root").style.setProperty('--red', rgb.r);
			document.querySelector(":root").style.setProperty('--green', rgb.g);
			document.querySelector(":root").style.setProperty('--blue', rgb.b);
			document.querySelector(":root").style.setProperty('--threshold', 0.8);
		}
	}
};
</script>
