<template>
    <div>
        <loadingPage :show="loading"></loadingPage>
        <v-card class="main-card d-flex" v-if="!loading">
            <v-card-text class="main-card--card-text">
               <slot name="cardContent"></slot>
            </v-card-text>
        </v-card>
    </div>

</template>
<script>
export default {
    props: ['loading'],
    
   
}
</script>

<style lang="scss">
    
</style>