import BaseApi from "./BaseApi";

export default class ReportService extends BaseApi {


    constructor(){
        super();
    }


    receivableAccounts()
    {
        return this.http.get(`${this.baseUrl}/reports/get-receivable-accounts`)
        .catch(this.handleErrorResponse);
    }

    exportReceivableAccounts()
    {
        return this.http.get(`${this.baseUrl}/reports/get-receivable-accounts/export`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    receivableAccountsByClient(client_id)
    {
        return this.http.get(`${this.baseUrl}/reports/get-receivable-accounts-by-client/${client_id}`)
        .catch(this.handleErrorResponse);
    }

    exportReceivableAccountsByClient(client_id)
    {
        return this.http.get(`${this.baseUrl}/reports/get-receivable-accounts-by-client/${client_id}/export`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }



    //
    //DEBS TO PAY
    //

    debtsToPay()
    {
        return this.http.get(`${this.baseUrl}/reports/get-debts-to-pay`)
        .catch(this.handleErrorResponse);
    }

    exportDebtsToPay()
    {
        return this.http.get(`${this.baseUrl}/reports/get-debts-to-pay/export`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    debtsToPayBySupplier(supplier_id)
    {
        return this.http.get(`${this.baseUrl}/reports/get-debts-to-pay-by-supplier/${supplier_id}`)
        .catch(this.handleErrorResponse);
    }

    exportDebtsToPayBySupplier(supplier_id)
    {
        return this.http.get(`${this.baseUrl}/reports/get-debts-to-pay-by-supplier/${supplier_id}/export`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    
    //
    //Purchase orders by work
    //
    
    purchaseOrdersByWork(work_id)
    {
        return this.http.get(`${this.baseUrl}/reports/get-purchase-orders-by-work/${work_id}`)
        .catch(this.handleErrorResponse);
    }

    exportPurchaseOrdersByWork(work_id)
    {
        return this.http.get(`${this.baseUrl}/reports/get-purchase-orders-by-work/${work_id}/export`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }



    //
    //Purchase orders varians by work
    //
    
    variantsPurchaseOrdersByWork(work_id)
    {
        return this.http.get(`${this.baseUrl}/reports/get-variants-purchase-orders-by-work/${work_id}`)
        .catch(this.handleErrorResponse);
    }

    exportVariantsPurchaseOrdersByWork(work_id)
    {
        return this.http.get(`${this.baseUrl}/reports/get-variants-purchase-orders-by-work/${work_id}/export`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }


    //
    //Purchase orders by variant
    //
    
    purchaseOrdersByVariant(variant_id)
    {
        return this.http.get(`${this.baseUrl}/reports/get-purchase-orders-by-variant/${variant_id}`)
        .catch(this.handleErrorResponse);
    }

    exportPurchaseOrdersByVariant(variant_id)
    {
        return this.http.get(`${this.baseUrl}/reports/get-purchase-orders-by-variant/${variant_id}/export`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

}