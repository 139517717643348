<template>
    <div>
        <div :class="`select__container rounded ${classesContainer}`">
            <div class="select__label">
                <span class="select__label--text body-2">{{label}}</span>
            </div>
            
            <div class="select__input">
                
                <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        prefix="|"
                        color="primary"
                        flat
                        solo
                        dense
                        v-model="value"
                        class="body-2 alt-date-pick"
                        append-icon="mdi-calendar"
                        hide-details="auto" 
                        v-bind="attrs"
                        v-on="on"
                        :rules="rule"
                        background-color='transparent'
                    ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="value"
                        @input="menu = false"
                        color="primary"
                    ></v-date-picker>
                </v-menu>
            </div>
        </div>
       
        <v-progress-linear color="primary" indeterminate  height="2" v-if="loading"></v-progress-linear>
    </div>
    
</template>
<script>
export default {
    props: ['classesContainer','valueInput', 'rule', 'label', 'items', 'loading', 'disabled'],
    computed: {
        value: {
            get: function() {
                return this.valueInput
            },
            set: function(value) {
                this.$emit('valueChange', value)
            }
        },
       
	},

    data(){
        return {
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false
        }
    }
        
}
    
    
</script>

<style lang="scss">


    .select{
        &__container{
            padding-left: 10px;
            border: $lineColor-1 1px solid;
            display: flex;
            align-items: baseline;
        }

        &__label{
            cursor: default;
            min-width: fit-content;
            &--text{
                //font-weight: bold !important;
               
            }
        }

        &__input{
            // margin-left: 10px;
            margin-top: 0px !important;
            width: -webkit-fill-available;
        }
    }
    .alternative-selector{
        // min-width: 1rem !important;
        // padding: 0.3125rem !important;
        // padding-bottom: px !important;
        margin-top: 0px !important;
        
    }

    // .v-text-field .v-input__control .v-input__slot input, select{
    //   //margin-bottom: 8px;
    //     margin-top: 0px !important;
    //     padding: 0;
    // }
    #alt-selector{
        
        margin-top: 2px !important;
        // font-size: px !important;
        font-weight: 400 !important;
        padding-right: 0 !important;
        
    }
    .border{
        
    }
    .alt-date-pick .v-input__control .v-input__slot input{
        margin-top: 0px !important;
    }
</style>