import Vue from "vue";

//Layout
Vue.component('dashboard', require("../layouts/Dashboard.vue").default);
Vue.component('login', require("../layouts/Login.vue").default);
Vue.component('loading', require("../layouts/Loading.vue").default);


//components
Vue.component('primaryButton', require("./primaryButton.vue").default);
Vue.component('secondaryButton', require("./SecondaryButton.vue").default);
Vue.component('datatable', require('./Datatable.vue').default);
Vue.component('datatablePagination', require('./DatatablePagination.vue').default);
Vue.component('modal', require("./Modal.vue").default);
Vue.component('alternativeTextField2', require("./AlternativeTextField2.vue").default);
Vue.component('tableItems', require("./TableItems.vue").default);
Vue.component('loadingPage', require("./LoadingPage.vue").default);
Vue.component('contentCard', require("./ContentCard.vue").default);
Vue.component('contentCardIndex', require("./ContentCardIndex.vue").default);
Vue.component('textFieldForm', require("./TextFieldForm.vue").default);
Vue.component('autocompleteOneForm', require("./AutocompleteOneForm.vue").default);
Vue.component('datePickerForm', require("./DatePickerForm.vue").default);
Vue.component('fileInputForm', require("./FileInputForm.vue").default);
Vue.component('radioItemForm', require("./RadioItemForm.vue").default);
Vue.component('addressCodeSelector', require("./AddressCodeSelects.vue").default);
Vue.component('iconButton', require("./IconButton.vue").default);
Vue.component('textAreaForm', require("./TextAreaForm.vue").default);
Vue.component('alternativeButton', require("./AlternativeButton.vue").default);
Vue.component('switchForm', require("./SwitchForm.vue").default);
Vue.component('autocompleteMultipleForm', require("./AutocompleteMultipleForm.vue").default);
Vue.component('alternativeDateRangePicker', require("./AlternativeDateRangePicker.vue").default);
Vue.component('checkboxForm', require("./CheckboxForm.vue").default);
Vue.component('alternativeAutocompleteOne', require("./AlternativeAutocompleteOne.vue").default);
