import BaseApi from "./BaseApi";

export default class PurchaseOrderService extends BaseApi {

    constructor(){
        super();
    }

    async list()
    {
        return this.http.get(`${this.baseUrl}/purchase-orders`)
        .catch(this.handleErrorResponse);
    }

    async get(id)
    {
        return this.http.get(`${this.baseUrl}/purchase-orders/${id}`)
        .catch(this.handleErrorResponse);
    }

    async store(data)
    {
        return this.http.post(`${this.baseUrl}/purchase-orders`, data)
        .catch(this.handleErrorResponse);
    }

    async update(id, data)
    {
        return this.http.put(`${this.baseUrl}/purchase-orders/${id}`, data)
        .catch(this.handleErrorResponse);
    }

    async delete(id)
    {
        return this.http.delete(`${this.baseUrl}/purchase-orders/${id}`)
        .catch(this.handleErrorResponse);
    }

    pdf(id)
    {
        return this.http.get(`${this.baseUrl}/purchase-orders/${id}/pdf`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    async sendMail(id)
    {
        return this.http.get(`${this.baseUrl}/purchase-orders/${id}/send-email`)
        .catch(this.handleErrorResponse);
    }

    async getSelect()
    {
        return this.http.get(`${this.baseUrl}/purchase-orders/gets/getSaleOrders`)
        .catch(this.handleErrorResponse);
    }


    //
    // Payments
    //
    async storePayment(saleOrderId, data)
    {
        return this.http.post(`${this.baseUrl}/purchase-orders/${saleOrderId}/payments`, data)
        .catch(this.handleErrorResponse);
    }

    async getPayment(saleOrderId, paymentId)
    {
        return this.http.get(`${this.baseUrl}/purchase-orders/${saleOrderId}/payments/${paymentId}`)
        .catch(this.handleErrorResponse);
    }

    async updatePayment(saleOrderId, paymentId, data)
    {
        return this.http.post(`${this.baseUrl}/purchase-orders/${saleOrderId}/payments/${paymentId}`, data)
        .catch(this.handleErrorResponse);
    }

    async deletePayment(saleOrderId, paymentId)
    {
        return this.http.delete(`${this.baseUrl}/purchase-orders/${saleOrderId}/payments/${paymentId}`)
        .catch(this.handleErrorResponse);
    }
    
    
}