import BaseApi from "./BaseApi";

export default class QuotationService extends BaseApi {

    constructor(){
        super();
    }

    async list()
    {
        return this.http.get(`${this.baseUrl}/quotations`)
        .catch(this.handleErrorResponse);
    }

    async get(id)
    {
        return this.http.get(`${this.baseUrl}/quotations/${id}`)
        .catch(this.handleErrorResponse);
    }

   

    async store(data)
    {
        return this.http.post(`${this.baseUrl}/quotations`, data)
        .catch(this.handleErrorResponse);
    }

    async update(id, data)
    {
        return this.http.put(`${this.baseUrl}/quotations/${id}`, data)
        .catch(this.handleErrorResponse);
    }

    async delete(id)
    {
        return this.http.delete(`${this.baseUrl}/quotations/${id}`)
        .catch(this.handleErrorResponse);
    }

    async sendMail(id)
    {
        return this.http.get(`${this.baseUrl}/quotations/${id}/send-email`)
        .catch(this.handleErrorResponse);
    }

    pdf(id)
    {
        return this.http.get(`${this.baseUrl}/quotations/${id}/pdf`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }


}