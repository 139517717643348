
// import router from "../router/index";
// import store from "../store";
import BaseApi from "./BaseApi";

export default class AuthService extends BaseApi {


    constructor(){
        super();
    }
 
    /**
     * LOGIN
     * @param {string} email user's email
     * @param {string} password user's password
     */
    async login(email, password)
    {
        return this.http.post(`${this.baseUrl}/users/sign_in`,{ user:{
            email: email,
            password: password
        }
            
        })
        .catch(this.handleErrorResponse);
    }


    async me()
    {
        return this.http.get(`${this.baseUrl}/users/auth/me`)
        .catch(this.handleErrorResponse);
    }


    async getPermissions()
    {
        return this.http.get(`${this.baseUrl}/getPermissions`)
        .catch(this.handleErrorResponse);
    }


}