import BaseApi from "./BaseApi";

export default class RequisitionService extends BaseApi {

    constructor(){
        super();
    }

    async list()
    {
        return this.http.get(`${this.baseUrl}/requisitions`)
        .catch(this.handleErrorResponse);
    }

    async get(id)
    {
        return this.http.get(`${this.baseUrl}/requisitions/${id}`)
        .catch(this.handleErrorResponse);
    }

   

    async store(data)
    {
        return this.http.post(`${this.baseUrl}/requisitions`, data)
        .catch(this.handleErrorResponse);
    }

    async update(id, data)
    {
        return this.http.put(`${this.baseUrl}/requisitions/${id}`, data)
        .catch(this.handleErrorResponse);
    }

    async delete(id)
    {
        return this.http.delete(`${this.baseUrl}/requisitions/${id}`)
        .catch(this.handleErrorResponse);
    }

}