import BaseApi from "./BaseApi";

export default class WorkService extends BaseApi {

    constructor(){
        super();
    }

    async list()
    {
        return this.http.get(`${this.baseUrl}/works`)
        .catch(this.handleErrorResponse);
    }

    async get(id)
    {
        return this.http.get(`${this.baseUrl}/works/${id}`)
        .catch(this.handleErrorResponse);
    }

   

    async store(data)
    {
        return this.http.post(`${this.baseUrl}/works`, data)
        .catch(this.handleErrorResponse);
    }

    async update(id, data)
    {
        return this.http.put(`${this.baseUrl}/works/${id}`, data)
        .catch(this.handleErrorResponse);
    }

    async delete(id)
    {
        return this.http.delete(`${this.baseUrl}/works/${id}`)
        .catch(this.handleErrorResponse);
    }


    async getSelect(){
        return this.http.get(`${this.baseUrl}/works/gets/get-works`)
        .catch(this.handleErrorResponse);
    }
}