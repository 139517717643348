import BaseApi from "./BaseApi";

export default class SaleOrderService extends BaseApi {

    constructor(){
        super();
    }

    async list()
    {
        return this.http.get(`${this.baseUrl}/sale-orders`)
        .catch(this.handleErrorResponse);
    }

    async get(id)
    {
        return this.http.get(`${this.baseUrl}/sale-orders/${id}`)
        .catch(this.handleErrorResponse);
    }

    async store(data)
    {
        return this.http.post(`${this.baseUrl}/sale-orders`, data)
        .catch(this.handleErrorResponse);
    }

    async update(id, data)
    {
        return this.http.post(`${this.baseUrl}/sale-orders/${id}`, data)
        .catch(this.handleErrorResponse);
    }

    async delete(id)
    {
        return this.http.delete(`${this.baseUrl}/sale-orders/${id}`)
        .catch(this.handleErrorResponse);
    }

    pdf(id)
    {
        return this.http.get(`${this.baseUrl}/sale-orders/${id}/pdf`,
        {
            responseType: "blob",
            headers:  { "Access-Control-Allow-Origin": "*" },
            exposedHeaders: ["Content-Disposition"],
        })
        .catch(this.handleErrorResponse);
    }

    async getSelect()
    {
        return this.http.get(`${this.baseUrl}/sale-orders/gets/getSaleOrders`)
        .catch(this.handleErrorResponse);
    }


    //
    // Payments
    //
    async storePayment(saleOrderId, data)
    {
        return this.http.post(`${this.baseUrl}/sale-orders/${saleOrderId}/payments`, data)
        .catch(this.handleErrorResponse);
    }

    async getPayment(saleOrderId, paymentId)
    {
        return this.http.get(`${this.baseUrl}/sale-orders/${saleOrderId}/payments/${paymentId}`)
        .catch(this.handleErrorResponse);
    }

    async updatePayment(saleOrderId, paymentId, data)
    {
        return this.http.post(`${this.baseUrl}/sale-orders/${saleOrderId}/payments/${paymentId}`, data)
        .catch(this.handleErrorResponse);
    }

    async deletePayment(saleOrderId, paymentId)
    {
        return this.http.delete(`${this.baseUrl}/sale-orders/${saleOrderId}/payments/${paymentId}`)
        .catch(this.handleErrorResponse);
    }



    //
    // Tracings
    //

    async storeTracing(saleOrderId, data)
    {
        return this.http.post(`${this.baseUrl}/sale-orders/${saleOrderId}/tracings`, data)
        .catch(this.handleErrorResponse);
    }

    async getTracing(saleOrderId, tracingId)
    {
        return this.http.get(`${this.baseUrl}/sale-orders/${saleOrderId}/tracings/${tracingId}`)
        .catch(this.handleErrorResponse);
    }

    async updateTracing(saleOrderId, tracingId, data)
    {
        return this.http.post(`${this.baseUrl}/sale-orders/${saleOrderId}/tracings/${tracingId}`, data)
        .catch(this.handleErrorResponse);
    }

    async deleteTracing(saleOrderId, tracingId)
    {
        return this.http.delete(`${this.baseUrl}/sale-orders/${saleOrderId}/tracings/${tracingId}`)
        .catch(this.handleErrorResponse);
    }

    
    
}