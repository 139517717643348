<template>
    <v-checkbox
        class="mt-0"
        v-model="value"
        :hide-details="hideDetails"
        :value="valueCheck"
        :rules="rules"
    >
        <template slot="label">
            <div class="mt-1 ml-1">
                {{label}}
            </div>
        </template>
    </v-checkbox>
</template>

<script>
export default {
    props: ['valueInput', 'rules', 'label','valueCheck', 'hideDetails'],
    model: {
        prop: 'value',
        event: 'valueChange'
    },
    computed: {
        value: {
            get: function() {
                return this.valueInput
            },
            set: function(value) {
                this.$emit('valueChange', value)
            }
        }
    }
}
</script>