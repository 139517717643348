<template>
    
    <v-app-bar app color="background" elevation="0"  class="app-header " >
        <v-app-bar-nav-icon class="mx-0 d-lg-none" @click.stop="$store.state.menu = !$store.state.menu"></v-app-bar-nav-icon>
        <div class="d-flex flex-column align-left">
            <!-- <v-btn v-if="$store.state.menu" dark icon text @click="$store.state.minimenu = !$store.state.minimenu">
                <v-icon>mdi-menu</v-icon>
            </v-btn> --> 
            
            <transition name="fade" mode="out-in">
             <h4 class="page-title" data-cy="navbar-route-title">{{ $route.meta.title }}</h4>
           
            </transition>
            
            <span class="caption d-none d-md-flex">{{hours}}:{{minutes}}:{{seconds}} | {{getDate}}</span>
        </div>

        <v-spacer></v-spacer>
        

        <v-menu offset-y>
            
            <template v-slot:activator="{ on }">
                <v-btn data-cy="navbar-user-info-button"  v-on="on" color="white" elevation="0" class="user-button">
                    <v-avatar color="primary" rounded size="30">
                        <span class="white--text">{{user[0]}}</span>
                    </v-avatar>
                    <span class="mx-2 d-none d-sm-flex">{{user}}</span>
                    
                    <!-- <v-icon>mdi-chevron-down</v-icon> -->
                </v-btn>
            </template>
            <v-list>
                <v-list-item data-cy="username-btn" v-for="(item, index) in user_menu" :key="index">
                    <v-list-item-title  >{{ item.title }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout" link>
                    <v-list-item-title data-cy="logout-btn">Cerrar sesión</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
        <div class="line-app-header">
                    
            <template>
                <v-progress-linear indeterminate :hidden="!this.$store.state.loading"></v-progress-linear>
            </template>
        </div>
    
    
    </v-app-bar>
  
   
</template>

<script>
import store from '../store';
import TokenManager from '../helpers/TokenManager';
    export default {
        name: 'Navbar',
        data(){
            return {
                
                hours: 0,
                minutes: 0,
                seconds: 0,
                months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],

                dialog: false,
                items: [],
                user_menu: [],
                menu: [
                    {
                        title: 'Feature',
                        link: '/'
                    },
                ]
            }
        },
        watch: {
			[`this.$store.state.userData`]() {
				console.log('ok');
			}
		},
        computed: {
            getDate: function(){
                const actualDate = new Date();
                return `${actualDate.getDate()} ${this.months[actualDate.getMonth()]} ${actualDate.getFullYear()}`;
            },
            user: function() {
                return this.$store.state.loggedUsername
            },
            warehouseSelected: function(){
                try {
                    console.log(this.$store.state.userData)
                    if(this.$store.state.userData !== null){
                        let selected = (localStorage.getItem("warehouse_chronos_erp"));
                        
                        if(selected === null){
                            selected = this.$store.state.userData.warehouses[0];
                            store.state.warehouse = selected.slug;
                            return selected;
                        }
                        selected = JSON.parse(selected);
                    
                        let found = this.$store.state.userData.warehouses.find((item) => item.slug == selected.slug);
                        
                        if(found == undefined)
                            selected = this.$store.state.userData.warehouses[0]??{"slug":''};

                        store.state.warehouse = selected.slug
                        return selected;
                    }
                } catch (error) {
                    console.log(error)
                }
                return '';
            },
            warehouseList: function(){
                if(this.$store.state.userData !== null){
                    return this.$store.state.userData.warehouses;
                }
                return [];
            }
        },
        methods: {

            
            getTime: function(){
                setInterval(() => {
                    const date = new Date()
                    this.hours = date.getHours()
                    this.minutes = this.checkSingleDigit(date.getMinutes())
                    this.seconds = this.checkSingleDigit(date.getSeconds())
                }, 1000)
            },
            checkSingleDigit (digit) {
                return ('0' + digit).slice(-2)
            },

            selectWarehouse(warehouse){
                localStorage.setItem("warehouse_chronos_erp", JSON.stringify(warehouse));
                this.$store.state.warehouse = warehouse.slug;
                window.location.reload()
            },

            logout(){
                // const tokenManager = new TokenManager();
                TokenManager.removeToken();
                this.$router.push("/login");
                window.location.reload();
            
            },
        },
        mounted () {
            this.getTime();
        }
    }
</script>
<style lang="scss">
    .app-header{
        margin: 7px;
        box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75) !important;
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75) !important;
        -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75) !important;
        @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
            margin: 0px;
        }
    }

    .app-toolbar{
        padding: 0 !important;
    }
   .parent-class >>> .v-toolbar__content {
      padding: 0px !important;
    }

    .user-button{
        text-transform: unset !important;

        &-warehouse{
            @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                //width: 100px;
            }
        }
    }

    .page-title{
        @media (max-width: #{map-get($grid-breakpoints, 'sm')}) {
           font-size: 0.75rem;
        }
    }

    .line-app-header{
        width: calc(100% - 32px);
        height: 1.5px;
        background: $lineColor-1;
        position: absolute;
        bottom: 0px;
        left: 16px;
        z-index: -10;
    }

    .v-toolbar__content{
        margin: 0 7px !important;
    }

    .warehouse-list{
        max-height: 300px;
        overflow-y: auto;
    }
</style>