import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import "./components"
import "@/plugins/mixins";
import VueGates from 'vue-gates';

Vue.config.productionTip = false;

// Cypress automatically sets window.Cypress by default
if (window.Cypress) {
    window.__store__ = store
    window.__router__ = router
}

Vue.use(VueGates);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
