<template>
	<div>
		<v-card height="100vh" class="d-flex align-center" flat tile img="../images/background-login.png">
			<v-container fluid>
				<v-row justify="center" align-content="center" no-gutters>
					<v-col sm="7" md="4" lg="3">
						<v-card outlined height="100%" :loading="loginIn" tile>
							<v-card-text>
								<v-row justify="center">
									<v-col cols="9">
										<v-img class="mt-1" src="@/assets/logo-dark.png"></v-img>
									</v-col>
								</v-row>
								<v-row justify="center">
									<v-col cols="9" class="text-center">
										<h1 class="title font-weight-bold text-uppercase black--text">
											Iniciar sesión
										</h1>
									</v-col>
								</v-row>
								<v-row v-show="loginError">
									<v-col class="text-center">
										<span class="red--text text-error">{{ errorText }}</span>
									</v-col>
								</v-row>
								<v-row justify="center">
									<v-col cols="9" class="">
										<v-form ref="form" v-model="valid" lazy-validation v-if="login">
											<v-card tile flat>
												<v-card-text class="py-0">
													<v-row>
														<v-col class="py-0 px-0">
															<v-text-field
																color="primary"
																hide-details
																v-model="user"
																label="Correo"
																:rules="[rules.required, rules.email]"
                                                                data-cy="login-input-email"
															></v-text-field>
														</v-col>
													</v-row>
													<v-row>
														<v-col class="py-0 px-0">
															<v-text-field
																color="primary"
																hide-details
																v-model="password"
																:append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
																:type="show ? 'text' : 'password'"
																label="Contraseña"
																:rules="rules.password"
																@click:append="show = !show"
                                                                data-cy="login-input-password"
															></v-text-field>
														</v-col>
													</v-row>
												</v-card-text>
											</v-card>
											<!-- <v-row justify="end">
												<div class="pt-4 pb-0">
													<v-btn text small @click="login = !login">Olvidé mi contraseña</v-btn>
												</div>
											</v-row> -->
											<v-row>
												<v-col class="py-10 text-right">
													<v-btn data-cy="login-button-start-session" color="primary" block dark @click="loginApi">Iniciar sesión</v-btn>
												</v-col>
											</v-row>
										</v-form>
										<v-form ref="restore_form" v-model="valid" lazy-validation v-else>
											<v-row>
												<v-col>
													<v-text-field
														v-model="user"
														append-icon="alternate_email"
														label="Email"
													></v-text-field>
												</v-col>
											</v-row>
											<v-row justify="end" align="center">
												<v-col>
													<v-btn text small color="primary" @click="login = !login"><v-icon left small>keyboard_backspace</v-icon>   Regresar</v-btn>
												</v-col>
												<v-col>
													<v-btn color="primary" dark>Restablecer</v-btn>
												</v-col>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-col>
					<!-- <v-col sm="5" lg="3">
						<v-card outlined height="100%" class="d-flex justify-center" img="/img/cover.png">
							<v-img src="@/assets/Yego-white.png" contain max-width="60%"></v-img>
						</v-card>
					</v-col> -->
				</v-row>
				<br>
				<v-row justify="center" align-content="center" no-gutters>
					<v-col cols="6" lg="3">
						<v-row align="center">
							<v-col cols="6" class="pa-0 text-right">
								<span class="white--text font-weight-light overline font-italic">Powered by</span>
							</v-col>
							<v-col cols="2" class="px-1">
								<v-img src="@/assets/logo-yntech-white.png"></v-img>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</div>
</template>

<script>
import TokenManager from '../helpers/TokenManager'
export default {
	data: () => ({
		login: true,
		loginIn: false,
		valid: false,
		show: false,
		rememberMe: false,
		user: '',
		password: '',
		loginError: false,
		errorText: '',
		rules: {
			required: value => !!value || 'Correo requerido',
			email: value => {
				const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				return pattern.test(value) || 'Correo no válido'
			},
			password: [
				v => !!v || 'Contraseña requerida'
			],
		}
	}),
	methods: {
		loginApi(){
			if(this.$refs.form.validate()){
				this.loginIn = true
				this.loginError = false
				this.$api.auth.login(this.user, this.password)
				.then((response) => {
                    if(response.status == 200 || response.status == 201){
                        console.log(response.headers.authorization);
						const token = response.headers.authorization
                        console.log(token)
						TokenManager.setToken(token);
						this.$api.auth.reloadToken();
                        this.$store.state.userData = response.data.user;
                        this.$store.state.loggedUsername = response.data.user.employee.name;
						// this.$router.push('estado_de_cuenta')
						// this.$router.push('/')
                        window.location.reload();
					}else{
						TokenManager.removeToken();
						this.loginError = true
						switch (response.data.error) {
							case 'invalid_grant':
								this.errorText = 'Usuario y/o contraseña incorrectos'
								break;
							default:
								this.errorText = 'Error en servidor. Intente nuevamente.'
								break;
						}
					}
				})
				.catch((error) => {
					TokenManager.removeToken();
					console.log(error, "error desde login");
					this.loginError = true
					if (error.response) {
						if(error.response.status == 401 || error.response.status == 400){
							this.errorText = 'Usuario y/o contraseña incorrectos';
							return;
						}
					}
					this.errorText = 'Error en servidor. Intente nuevamente.'
				})
				.finally(() => {
					this.loginIn = false
				})
			}
		}
	}
}
</script>

<style>

</style>