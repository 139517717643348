import BaseApi from "./BaseApi";

export default class BankAccountService extends BaseApi {

    constructor(){
        super();
    }

    async list()
    {
        return this.http.get(`${this.baseUrl}/banks`)
        .catch(this.handleErrorResponse);
    }

    async get(id)
    {
        return this.http.get(`${this.baseUrl}/banks/${id}`)
        .catch(this.handleErrorResponse);
    }
    
    async getSelect()
    {
        return this.http.get(`${this.baseUrl}/banks/gets/getBanks`)
        .catch(this.handleErrorResponse);
    }
    
    
    
    async store(data)
    {
        return this.http.post(`${this.baseUrl}/banks`, data)
        .catch(this.handleErrorResponse);
    }
    
    
    async update(id, data)
    {
        return this.http.put(`${this.baseUrl}/banks/${id}`, data)
        .catch(this.handleErrorResponse);
    }
    
    async delete(id)
    {
        return this.http.delete(`${this.baseUrl}/banks/${id}`)
        .catch(this.handleErrorResponse);
    }
    
    //
    //TRANSACTIONS
    //
    
    async getTransaction(bank_id, transaction_id)
    {
        return this.http.get(`${this.baseUrl}/banks/${bank_id}/transactions/${transaction_id}`)
        .catch(this.handleErrorResponse);
    }

    async storeTransaction(bank_id, data)
    {
        return this.http.post(`${this.baseUrl}/banks/${bank_id}/transactions`, data)
        .catch(this.handleErrorResponse);
    }

    async updateTransaction(bank_id, transaction_id, data)
    {
        return this.http.post(`${this.baseUrl}/banks/${bank_id}/transactions/${transaction_id}`, data)
        .catch(this.handleErrorResponse);
    }

    async deleteTransaction(bank_id, transaction_id)
    {
        return this.http.delete(`${this.baseUrl}/banks/${bank_id}/transactions/${transaction_id}`)
        .catch(this.handleErrorResponse);
    }

}